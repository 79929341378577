export default {
  "user.login.userName": "用户名",
  "user.login.password": "密码",
  "user.login.username.placeholder": "请输入用户名",
  "user.login.password.placeholder": "请输入密码",
  "user.login.message-invalid-credentials":
    "账户或密码错误（admin/ant.design）",
  "user.login.message-invalid-verification-code": "验证码错误",
  "user.login.tab-login-credentials": "账户密码登录",
  "user.login.tab-login-mobile": "手机号登录",
  "user.login.mobile.placeholder": "手机号",
  "user.login.mobile.verification-code.placeholder": "验证码",
  "user.login.remember-me": "自动登录",
  "user.login.forgot-password": "忘记密码",
  "user.login.sign-in-with": "其他登录方式",
  "user.login.signup": "注册用户",
  "user.login.login": "登录",
  "user.register.register": "注册",
  "user.register.userName": "用户名",
  "user.register.username.placeholder": "用户名长度至少为3位",
  "user.register.email.placeholder": "邮箱",
  "user.register.password.placeholder":
    "密码由8位数字、大小写字母和特殊符号组成!",
  "user.register.password.popover-message":
    "密码由8位数字、大小写字母和特殊符号组成!",
  "user.register.confirm-password.placeholder": "确认密码",
  "user.register.get-verification-code": "获取验证码",
  "user.register.sign-in": "使用已有账户登录",
  "user.register.result-confirm-msg":
    "已阅读并同意执行 T/GDTEX 23（所有部分）《纺织工业互联网标识信息规范》",
  "user.register.result-confirm.required": "请阅读并同意!",
  "user.register-result.msg": "你的账户：{email} 注册成功",
  "user.register-result.activation-email":
    "激活邮件已发送到你的邮箱中，邮件有效期为24小时。请及时登录邮箱，点击邮件中的链接激活帐户。",
  "user.register-result.back-home": "返回首页",
  "user.register-result.view-mailbox": "查看邮箱",
  "user.user.required": "请输入用户名！",
  "user.phone.required": "请输入手机号码！",
  "user.phone.wrong-format": "邮箱地址格式错误！",
  "user.email.required": "请输入邮箱地址！",
  "user.email.wrong-format": "邮箱地址格式错误！",
  "user.userName.required": "请输入帐户名或邮箱地址",
  "user.password.required": "请输入密码！",
  "user.password.twice.msg": "两次输入的密码不匹配!",
  "user.password.strength.msg": "密码强度不够 ",
  "user.password.strength.strong": "强度：强",
  "user.password.strength.medium": "强度：中",
  "user.password.strength.low": "强度：低",
  "user.password.strength.short": "强度：太短",
  "user.confirm-password.required": "请确认密码！",
  "user.phone-number.required": "请输入正确的手机号",
  "user.phone-number.wrong-format": "手机号格式错误！",
  "user.verification-code.required": "请输入验证码！",
};
