<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      locale: zh_CN,
    };
  },
  computed: {
    ...mapGetters(["token", "userInfo"]),
  },
  created() {
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("token", this.token);
      sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
    });
    if (sessionStorage.getItem("userInfo")) {
      this.SET_USER_INFO(JSON.parse(sessionStorage.getItem("userInfo")));
    }
    if (sessionStorage.getItem("token")) {
      this.SET_TOKEN(sessionStorage.getItem("token"));
    }
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userInfo");
  },
  methods: {
    ...mapMutations(["SET_TOKEN", "SET_USER_INFO"]),
  },
};
</script>

<style lang="less">
html {
  font-size: 5.2083333vw;
  margin: 0 auto;
  body {
    font-size: 0.12rem;
    line-height: 1.5;
    color: #000;
    background: #fff;
  }
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 100px;
    max-width: 1920px;
  }
}
</style>
