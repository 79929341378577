export default {
  "menu.news": "News",
  "menu.research": "Research",
  "menu.approval": "Approval",
  "menu.opinion": "Opinion",
  "menu.release": "Release",
  "menu.suggestions": "Suggestions",
  "menu.platform-login": "Platform Login",
  "menu.code-search": "Code Search",
  "menu.cooperative-partner": "Cooperative Partner",
  "menu.solution": "Solution",
  "menu.total-codes": "Total Codes",
  "menu.download": "Download",
  "menu.group": "Group",
  "menu.partners": "Partners",
  "menu.vipNews": "Vip News",
  "menu.aboutUs": "About Us",
  "menu.businessStandard": "Business Standard",
  "menu.developerGuide": "Developer Guide",
  "menu.useage": "Use Guide",
  "menu.welcome": "welcome",
  "menu.home": "Home",
  "menu.index": "Home",
  "menu.dashboard": "Dashboard",
  "menu.dashboard.analysis": "Analysis",
  "menu.dashboard.monitor": "Monitor",
  "menu.dashboard.workplace": "Workplace",
  "menu.form": "Form",
  "menu.form.basic-form": "Basic Form",
  "menu.form.step-form": "Step Form",
  "menu.form.step-form.info": "Step Form(write transfer information)",
  "menu.form.step-form.confirm": "Step Form(confirm transfer information)",
  "menu.form.step-form.result": "Step Form(finished)",
  "menu.form.advanced-form": "Advanced Form",
  "menu.list": "List",
  "menu.list.table-list": "Search Table",
  "menu.list.basic-list": "Basic List",
  "menu.list.card-list": "Card List",
  "menu.list.search-list": "Search List",
  "menu.list.search-list.articles": "Search List(articles)",
  "menu.list.search-list.projects": "Search List(projects)",
  "menu.list.search-list.applications": "Search List(applications)",
  "menu.profile": "Profile",
  "menu.profile.basic": "Basic Profile",
  "menu.profile.advanced": "Advanced Profile",
  "menu.result": "Result",
  "menu.result.success": "Success",
  "menu.result.fail": "Fail",
  "menu.exception": "Exception",
  "menu.exception.not-permission": "403",
  "menu.exception.not-find": "404",
  "menu.exception.server-error": "500",
  "menu.exception.trigger": "Trigger",
  "menu.account": "Account",
  "menu.account.center": "Account Center",
  "menu.account.settings": "Account Settings",
  "menu.account.trigger": "Trigger Error",
  "menu.account.logout": "Logout",
};
