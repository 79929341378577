<template>
  <footer :style="{ background: bgColor }">
    版权所有：<a href="https://beian.miit.gov.cn" target="_blank"
      >粤ICP备18064028号</a
    >
  </footer>
</template>
<script>
export default {
  name: "Footer",
  props: {
    bgColor: {
      type: String,
      default: "#fff",
    },
  },
};
</script>
<style lang="less" scoped>
footer {
  text-align: center;
  height: 120px;
  line-height: 120px;
  font-size: 18px;
  color: #666666;
  a {
    color: #369afe;
  }
}
</style>
